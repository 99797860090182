import VueRouter from "vue-router";
//引入组件

// 创建一个路由器并暴露
export default new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("../pages/homePage.vue"),
      meta: {
        title: "首页",
      },
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/pages/homePage.vue"),
      meta: {
        title: "首页",
      },
    },
    {
      path: "/title/eventProfile",
      name: "eventProfile",
      component: () => import("../components/title/eventProfile.vue"),
      meta: {
        title: "活动简介",
      },
    },
    {
      path: "/erji/EventInformationErji.vue",
      name: "EventInformationErji",
      component: () => import("../components/erji/EventInformationErji.vue"),
      meta: {
        title: "新闻公告",
      },
    },

    {
      path: "/title/ScheduleDynamics",
      name: "ScheduleDynamics",
      component: () => import("../components/title/ScheduleDynamics.vue"),
      meta: {
        title: "赛程动态",
      },
    },

    {
      path: "/title/PersonalPage",
      name: "PersonalPage",
      component: () => import("../components/title/PersonalPage.vue"),
      meta: {
        title: "个人页面",
      },
    },

    {
      path: "/title/EventInformation",
      name: "EventInformation",
      component: () => import("../components/title/EventInformation.vue"),
      meta: {
        title: "赛事资讯",
      },
    },

    {
      path: "/title/StyleShowPgae",
      name: "StyleShowPgae",
      component: () => import("../components/title/StyleShowPgae.vue"),
      meta: {
        title: "风采展示",
      },
    },
    {
      path: "/title/informationPage",
      name: "informationPage",
      component: () => import("../components/title/informationPage.vue"),
      meta: {
        title: "教育资讯",
      },
    },

    {
      path: "/erji/StudentDetails.vue",
      name: "StudentDetails",
      component: () => import("../components/erji/StudentDetails.vue"),
      meta: {
        title: "学生详情",
      },
    },

    {
      path: "/erji/StudentPaper.vue",
      name: "StudentPaper",
      component: () => import("../components/erji/StudentPaper.vue"),
      meta: {
        title: "学生论文",
      },
    },

    {
      path: "/erji/zixunInfoPage.vue",
      name: "zixunInfoPage",
      component: () => import("../components/erji/zixunInfoPage.vue"),
      meta: {
        title: "资讯详情",
      },
    },

    {
      path: "/erji/jiaoshierjiPage.vue",
      name: "jiaoshierjiPage",
      component: () => import("../components/erji/jiaoshierjiPage.vue"),
      meta: {
        title: "教师详情",
      },
    },
    {
      path: "/erji/agreementPage.vue",
      name: "agreementPage",
      component: () => import("../components/erji/agreementPage.vue"),
      meta: {
        title: "协议页面",
      },
    },

    {
      path: "/navigation/shezhiPage.vue",
      name: "shezhiPage",
      component: () => import("../components/navigation/shezhiPage.vue"),
      meta: {
        title: "配置页面",
      },
    },
  ],
});

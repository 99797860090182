import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'vue-beauty/package/style/vue-beauty.min.css'
import vueBeauty from 'vue-beauty'

import request from "./utils/request";

Vue.use(vueBeauty)
// 引入路由
import VueRouter from 'vue-router' 
// 引入字体
import "./static/css/font/iconfont.css"
// 引入路由器
import router from './router/index'
// 引入公用css
import "./static/css/style/common.css"; 
import axios from "axios";
Vue.prototype.axios = axios;
Vue.prototype.$request = request;
Vue.use(ElementUI);
Vue.use(VueRouter)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
